import React, {useState} from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  styled,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const style = {
  position: 'absolute',
  width: '90vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  p: 2,
  marginLeft: '5%',
  marginTop: '20%',
};

const StyledAutoComplete = styled(Autocomplete)(({theme}) => ({
  margin: theme.spacing(3, 0),
  width: '80vw',
}));

const applianceStatusFilters = ['Offline', 'Impaired', 'Online'];
const cameraStatusFilters = ['Offline', 'Online'];
const cloudArchiveStatusFilters = ['Error', 'Warning', 'Good'];
const uploadSpeedFilters = ['Offline', 'Poor', 'Good', 'Excellent'];
const impairedCamerasFilters = ['Impaired', 'Unimpaired'];

const formatFilters = (filters) => {
  if (isEmpty(filters)) {
    return {
      bitrateQuality: [],
      caStatus: [],
      cameraStatus: [],
      status: [],
      impairedCameras: [],
    };
  }
  const formattedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (typeof filters[key] === 'string' && filters[key].length > 0) {
      formattedFilters[key] = filters[key].split(',').map(capitalize);
    } else {
      formattedFilters[key] = [];
    }
  });
  return formattedFilters;
};

const MobileGridFilter = ({
  filterValues,
  onFilterChange,
  caStatusAllowed,
  tamperFeatureFlag,
}) => {
  const [filterModel, setFilterModel] = useState(formatFilters(filterValues));
  const initialFilter = formatFilters(filterValues);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleApply = () => {
    onFilterChange(filterModel);
    setOpen(false);
  };

  const handleClear = () => {
    setFilterModel({
      bitrateQuality: [],
      caStatus: [],
      cameraStatus: [],
      status: [],
      impairedCameras: [],
    });
  };

  const handleClose = () => {
    setFilterModel(initialFilter);
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex', justifyContent: 'end'}}>
      <Button className="main-filter-button" onClick={handleOpen}>
        <FilterListIcon />
        FILTERS
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="filter-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
          <StyledAutoComplete
            multiple
            disablePortal
            id="appliance-status-filter"
            options={applianceStatusFilters}
            value={filterModel?.status}
            onChange={(e, newValues) =>
              setFilterModel((prev) => ({...prev, status: newValues}))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Appliance Status"
                placeholder="Select..."
              />
            )}
          />
          <StyledAutoComplete
            multiple
            disablePortal
            id="camera-status-filter"
            options={cameraStatusFilters}
            value={filterModel?.cameraStatus}
            onChange={(e, newValues) =>
              setFilterModel((prev) => ({...prev, cameraStatus: newValues}))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Camera Status"
                placeholder="Select..."
              />
            )}
          />
          {tamperFeatureFlag && (
            <Autocomplete
              multiple
              disablePortal
              id="camera-impaired-filter"
              options={impairedCamerasFilters}
              value={filterModel?.impairedCameras}
              onChange={(e, newValues) =>
                setFilterModel((prev) => ({
                  ...prev,
                  impairedCameras: newValues,
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Image Health"
                  placeholder="Select..."
                />
              )}
            />
          )}
          {caStatusAllowed && (
            <StyledAutoComplete
              multiple
              disablePortal
              id="cloud-archive-status-filter"
              options={cloudArchiveStatusFilters}
              value={filterModel?.caStatus}
              onChange={(e, newValues) =>
                setFilterModel((prev) => ({...prev, caStatus: newValues}))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Cloud Archive Status"
                  placeholder="Select..."
                />
              )}
            />
          )}
          <StyledAutoComplete
            multiple
            disablePortal
            id="upload-speed-filter"
            options={uploadSpeedFilters}
            value={filterModel?.bitrateQuality}
            onChange={(e, newValues) =>
              setFilterModel((prev) => ({...prev, bitrateQuality: newValues}))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Upload Speed"
                placeholder="Select..."
              />
            )}
          />
          <Box sx={{mt: 5, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              className="filter-clear-button"
              variant="text"
              sx={{mr: 2}}
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              className="filter-cancel-button"
              variant="text"
              sx={{mr: 2}}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="filter-apply-button"
              variant="contained"
              color="primary"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default MobileGridFilter;
