import {DateTime} from 'luxon';

export const mergeDayAndTime = (day, time) => {
  return DateTime.fromObject({
    year: day.year,
    month: day.month,
    day: day.day,
    hour: time.hour,
    minute: time.minute,
    second: time.second,
  });
};

export const isSameDay = (dateA, dateB) =>
  dateA &&
  dateB &&
  dateA.startOf('day').toISODate() === dateB.startOf('day').toISODate();

export const generateTemplateEntriesFromDateTimes = (startDate, endDate) => {
  const entries = [];

  let current = startDate.startOf('day');
  const endDay = endDate.startOf('day');

  while (current <= endDay) {
    const entry = {
      startDate: current.toFormat('yyyy-MM-dd'),
    };

    // One day exception
    if (isSameDay(startDate, endDate)) {
      entry.schedule = `${startDate.toFormat('HHmm')}:${endDate.toFormat(
        'HHmm',
      )}`;
    }
    // First day
    else if (isSameDay(current, startDate)) {
      entry.schedule = `${startDate.toFormat('HHmm')}:2400`;
    }
    // Last day
    else if (isSameDay(current, endDate)) {
      entry.schedule = `0000:${endDate.toFormat('HHmm')}`;
    }
    // Full days in between
    else {
      entry.schedule = '0000:2400';
    }

    entries.push(entry);
    current = current.plus({days: 1});
  }

  return entries;
};

const isConflict = (selectedStart, selectedEnd, assignedStart, assignedEnd) =>
  assignedStart < selectedEnd && assignedEnd > selectedStart;

export const getConflictingSchedules = (
  selectedSchedule,
  assignedSchedules,
) => {
  const selectedScheduleStart = DateTime.fromFormat(
    selectedSchedule.startDate,
    'yyyy-MM-dd HH:mm',
  );
  const selectedScheduleEnd = DateTime.fromFormat(
    selectedSchedule.endDate,
    'yyyy-MM-dd HH:mm',
  );

  const conflicts = assignedSchedules.filter((schedule) => {
    if (schedule.uuid === selectedSchedule.id) {
      return false;
    }
    const scheduleStart = DateTime.fromFormat(
      schedule.startDate,
      'yyyy-MM-dd HH:mm:ss',
    );
    const scheduleEnd = DateTime.fromFormat(
      schedule.endDate,
      'yyyy-MM-dd HH:mm:ss',
    );
    return isConflict(
      selectedScheduleStart,
      selectedScheduleEnd,
      scheduleStart,
      scheduleEnd,
    );
  });
  return conflicts;
};
